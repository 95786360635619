<template>
  <div class="caseVue">
    <header class="header">
      <h1 class="animated fadeInDown">公司季刊</h1>
      <h3 class="animated pulse">最新资讯</h3>
      <h5 class="animated fadeInUp">核心概念与产品方案展示</h5>
      <!-- <button @click="goNew">立即了解最新</button> -->
    </header>
    <main>
      <b-container v-if="quarterly.length > 0">
        <div v-for="(item, index) in quarterly" :key="index">
          <h1 class="bounceIn wow animated">{{ item.cnname }}</h1>

          <b-row class="mb-5" cols="1" cols-sm="2" cols-md="2" cols-lg="4">
            <b-col v-for="(jikan, ind) in item.data" :key="ind">
              <router-link
                class="jikan fadeInUp wow animated"
                :data-title="item.cnname + jikan.quarterlyName"
                :to="{ path: `/quarterlyFile/quarterly/${jikan.id}` }"
              >
                <!-- <h2>{{ jikan.quarterlyName }}</h2>
            <div class="show">
              {{ jikan.description }}
              <router-link
                :to="{ path: `/quarterlyFile/quarterly/${jikan.id}` }"
              >
                <button>查看详情</button>
              </router-link>
            </div>
            <div class="biao">
              <span v-for="(li, i) in jikan.title" :key="i">{{ li }}</span>
            </div> -->
              </router-link>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  //import引入的组件
  components: {},
  // mixins: [mixin],
  data() {
    return {
      quarterly: [],
    };
  },
  //方法集合
  methods: {
    goNew() {
      if (this.quarterly.length == 0) {
        return false;
      }
      let id = this.quarterly[0].data[0].id;
      this.$router.push({ path: `/quarterlyFile/quarterly/${id}` });
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  async mounted() {
    //   this.headerHeight =
    let _this = this;
    await this.$http.get("/getQuarterly").then((e) => {
      console.log(e);
      if (e.status == 200) {
        _this.quarterly = e.data;
        // 动画重启
        this.$nextTick(() => {
          // 在dom渲染完后,再执行动画
          var wow = new WOW({
            live: false,
          });
          wow.init();
        });
      }
    });
  },
};
</script>
<style lang="scss" scoped>
// @media (min-width: 1620px) {
//   .container {
//     max-width: 1620px;
//   }
// }
.jikan {
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  height: 400px;
  box-shadow: 11px 20px 57px 0 rgba(0, 0, 0, 0.1);
  background: url(../assets/images/quarterly.png) no-repeat center;
  background-size: 100% 100%;
  display: block;
  max-width: 300px;
  padding: 0;
  border-radius: 8px;
  margin: 10px auto;
  transition: 1s !important;
  &::before {
    content: attr(data-title);
    position: absolute;
    left: 50%;
    top: 168px;
    font-size: 10px;
    transform: translateX(-50%);
  }
  &:hover {
    transform: scale(1.05) !important;
    box-shadow: 11px 20px 57px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;
  }
}
.caseVue main {
  margin: 0;
  background: url("../assets/jikan/jikan.png") center center no-repeat;
  h1 {
    font-size: 30px;
    color: #000;
    font-weight: bold;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}
.header {
  background: linear-gradient(90deg, #2d5fcd 0, #7b5ab6 100%), #2839a0;
  button {
    border-radius: 2rem;
    margin-top: 3rem;
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
    transition: 1s;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #409eff;
    }
  }
}
.caseItem {
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 1.5rem !important;
  // margin: 0;
  margin-bottom: 20px;
  min-height: 460px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  & > * {
    margin-bottom: 10px;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  .content {
    text-indent: 2rem;
    text-align: justify;
  }
  & > img {
    width: calc(100% - 3rem);
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    transition: 0.5s all linear;
    &:hover {
      transform: scale(1.3) rotate(4deg);
      // transition: ;
    }
  }
}
.ibanner_box {
  text-align: center;
  color: #000;
  font-size: 3rem;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  p {
    font-size: 30px;
  }
}
</style>